export default (scrollTarget, offset, inFn, outFn) => {
  const contentTg = document.querySelector('.content')

  const handler = () => {
    try {
      const scrollTg = typeof scrollTarget === 'string' ? document.querySelector(scrollTarget) : scrollTarget
      const { top } = useElementBounding(scrollTg)
      if (!scrollTg) return

      const scroll = contentTg.scrollTop
      const target = scrollTg.offsetTop

      const value = scrollTg.isEqualNode(contentTg) ? target - scroll : top.value

      if (value + offset < 0) {
        inFn ? inFn(value + offset) : null
      } else {
        outFn ? outFn(value + offset) : null
      }
    } catch (e) {
      console.error(scrollTarget, e)
    }
  }

  useEventListener(contentTg, 'scroll', handler)

  onBeforeUnmount(() => {
    contentTg.removeEventListener('scroll', handler)
  })
}
